.image{
    display: flex;
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 5%; */
    width: 100%;
    height: 115vh;
   /* z-index: 1; */
   /* padding: 0; */
   position: relative;
   top: -150px;
   background-size: cover;
   background-image: linear-gradient(rgba(0, 0, 0, 0.714), rgba(0, 0, 0, 0.56)), url("https://i0.wp.com/www.igniteengineers.com/wp-content/uploads/2019/02/3.png?ssl=1");
}

.navbar{
    position: sticky;
    top: 0px;
    z-index: 1;
    /* position: absolute;  */
}

.animation{
    /* font-family: 'Inter', sans-serif; */
    font-family: 'Great Vibes', cursive;
    letter-spacing: -0.2%;
    font-weight: 400;
    color: rgb(197, 14, 14);
    font-size:clamp(24px,7vw + 0.1rem,96px);
}

.image .short-desc{
    text-align: center;
    font-family: 'Libre Baskerville', serif;
    padding-left: 25%;
    padding-right: 25%;
    color: white;
    font-size: clamp(4px,1.35vw + 0.1rem,24px);
}

.type::after {
    content: '|';
    animation: cursor 1.2s infinite step-start;
    color: whitesmoke;
  }

/* Style for the parent container */
.about-us {
  display: flex;
  /* flex-direction: column; */
  gap: 48px;
  /* width: 80%; */
  align-items: center;
  justify-content: center;
  text-align: left; 
  margin: 20px; /* Add some spacing between the two containers */
  padding: 20px;
  /* border: 1px solid #ddd;  */
  border-radius: 10px; /* Rounded corners for a modern look */
  /* background-color: #f7f7f7;  */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);  */
}

/* Style for the text inside the container */
.about-us p {
  margin: 10px 0; /* Add spacing between paragraphs */
  font-size: 24px;
  line-height: 1.5;
}

/* Style for the images */
.about-img1,
.about-img2 {
  max-width: 100%; /* Ensure images don't overflow the container */
  height: auto;
  border-radius: 5px; /* Rounded corners for images */
  margin-bottom: 10px; /* Add spacing below images */
}


  .imagesOfSponsors {
    margin-left: 4%;
    margin-right: 4%;
  }

  .imagesOfSponsors > .pic-container {
    display: inline-block;
  }

.heading {
     display: flex;
     margin: auto;
     padding: 12px;
     text-align: center;
     justify-content: center;
     font-weight: 800;
     margin-bottom: 64px;
     margin-top: 120px;
     font-size: 72px;
     border-radius: 10px;
     background-color: red;
     max-width: fit-content;
     /* color: azure; */
}

   
  @keyframes cursor {
    50% {
      opacity: 0;
    }
  }
