.footer {
    margin-top: 144px;
    background-color: black;
    color: white;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  h3 {
    margin-left: 15%;
    text-align: left;
    font-size: larger;
  }

  .elan-text {
    color: red;
  }
  
  .footer-text {
        text-align: left;        
  }

  .footer-list li {
    margin-top: 8px;
    list-style: none;
    font-size: large;
  }

  .footer-list li :hover {
    color: red;
  }

  .footer-list li a {
    /* list-style: none; */
    text-decoration: none;
    /* padding: 0 8px; */
    color: white;
  }
  