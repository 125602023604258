.carousel-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .custom-carousel {
    border: 2px solid #ccc; 
    border-radius: 8px; 
  }
  
  .custom-carousel .slide img {
    width: 100%; 
    max-height: 600px; 
    object-fit: cover; 
    border-radius: 8px; 
  }
  